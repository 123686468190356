
.member-header {
  width: 80% !important;
  left: 50%; /* Centering horizontally */
  transform: translateX(-50%); /* Move left by half its width */
  border-radius: 8px;
  position: fixed !important;
  top: 20px !important;
}

@media (max-width: 768px) {
  .member-header {
    width: 95% !important;
  } 
}
.club-event-card {
  padding: 20px;
  position: relative;
  background-color: #0bb6cd30 !important;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  min-height: 400px;
  z-index:2;
}


.event-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}


/* EventPage.css */

/* Prevent horizontal scrolling and overflow */
.swiper-container {
  max-width: 100%; /* Ensure swiper takes full width without overflow */
  overflow: hidden; /* Prevents horizontal scrolling */
  position: relative;
}

/* Make sure slides don't cause overflow */


/* Adjust the position of the next and previous buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #000; /* Change arrow color if needed */
  font-size: 20px; /* Adjust the size of the arrow */
  top: 50%; /* Center the arrows vertically */
  transform: translateY(-50%);
  position: absolute;
  top : 200px;
  z-index: 10;
}

.swiper-button-next {
  right: 40px; /* Move the next arrow closer to the card */
}

.swiper-button-prev {
  left: 40px; /* Move the previous arrow closer to the card */
}


@media (min-width: 1024px) {
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure slide takes full width */
    height: auto; /* Allow it to adjust based on content */
    padding: 20px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top:50%;
  }
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .swiper-button-next,
  .swiper-button-prev {
    font-size: 16px; /* Decrease arrow size on smaller screens */
  }

  .club-event-card {
    width: 100%; /* Make cards take up full width on smaller screens */
  }

  .swiper-slide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 576px) {
  /* Club Event Card Styling */
  .club-event-card {
    width: 100%; /* Full width on small screens */
    padding: 12px; /* Slightly reduced padding for compact layout */
    background-color: #0bb6cd30 !important;
    border-radius: 8px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    min-height: 300px; /* Reduced min-height for mobile */
    max-width: 100%;
  }

  /* Swiper Controls */
  .swiper-button-next,
  .swiper-button-prev {
    font-size: 12px; /* Smaller arrow size for compact screens */
    top: 200px; /* Center arrows vertically */
    transform: translateY(-50%);
    z-index: 10;
  }

  .swiper-button-next {
    right: 20px; /* Adjust spacing from card */
  }

  .swiper-button-prev {
    left: 20px; /* Adjust spacing from card */
  }

  /* Event Grid */
  .event-grid {
    display: grid;
    grid-template-columns: 1fr; /* Single column for small screens */
    gap: 16px; /* Reduced gap for compact layout */
  }

  /* Text & Typography Adjustments */
  .event-title {
    font-size: 1.1rem; /* Slightly reduced font size */
    font-weight: bold;
    margin-bottom: 8px;
    text-align: center; /* Center-align text for better readability */
  }

  .event-detail-text {
    font-size: 0.9rem; /* Smaller detail text for compact display */
    text-align: center;
  }

}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Layout for the dashboard */
.dashboard-container {
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
}

.dashboard-body {
  display: flex;
  height: fit-content;
  flex-direction: column;
  flex-grow: 1;
  max-width:calc(100% - 330px);
  padding-left: 290px;
  margin: 10px auto;
}

/* Header styling - now fixed */
.facultyMentorPage-header {
  position: fixed !important;
  left: 310px !important;
  width: calc(100% - 330px) !important;
  border-radius: 8px;
  padding: 15px !important;
  z-index: 100;
}

/* Sidebar styling */

.hamburger-icon {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #2c3e50;
  color: #ecf0f1;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  position: fixed;
  height: 100%;
  overflow-y: scroll;
  transition: width 0.3s ease;
}

.sidebar h6 {
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #ecf0f1;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  font-family: 'Open Sans', sans-serif;
  word-break: keep-all;
}

.sidebar :global(.MuiMenuItem-root) {
  color: #bdc3c7 !important;
  display: flex !important;
  margin-bottom: 3px !important;
  border-radius: 4px !important;
  align-items: flex-start !important;
  overflow-wrap: keep-all !important;
  transition: background-color 0.25s ease, transform 0.15s ease, box-shadow 0.2s !important;
}

.sidebar :global(.MuiMenuItem-root:last-child) {
  margin-bottom: 20px !important;
}

.sidebar :global(.MuiTypography-root) {
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.9rem !important;
}

.sidebar :global(.MuiMenuItem-root:hover) {
  background-color: #16a085 !important;
  color:white !important;
  transform: translateX(3px) !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1) !important;
}

.sidebar :global(.MuiSvgIcon-root) {
  font-size: 1.3rem !important;
  color: #e74c3c !important;
  transition: color 0.2s !important;
}

.sidebar :global(.MuiMenuItem-root:hover .MuiSvgIcon-root) {
  color: #ffffff !important;
}

:global(.MuiMenu-paper) {
  max-height: 200px !important;       
  overflow-y: auto !important;       
  display: flex !important;           
  flex-direction: column !important;  
}

:global(.MuiMenuList-root) {
  padding-top: 10px !important;
}

/* Ensures the MenuItem takes the full width of the parent */
:global(.MuiMenuItem-root) {
  width: 100% !important;
  display: block !important;          /* Make sure each item is block-level to fill the container */
}

/* Optional: Truncate text with ellipsis */
:global(.MuiMenuItem-root) {
  margin-bottom: 10px !important;
  padding: 12px !important;
  border-radius: 8px !important;
  transition: background-color 0.3s ease, transform 0.2s !important;
  align-items: center !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

/* Make the hover effect work across the entire item */
:global(.MuiMenuItem-root:hover) {
  background-color: #1abc9c !important;
  transform: translateX(5px) !important; 
  color: white !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15) !important;
}

.dashboard-content {
  flex-grow: 1;
  padding: 20px;
  margin-top: 120px;
  background-color: #f1f2f6;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Welcome card styling */
.welcome-card {
  word-break: keep-all;
  text-align: center;
  margin-bottom: 50px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: #2c3e50;
}

.welcome-card h4 {
  font-size: 2rem;
}

/* Action grid and card styling */
.action-grid {
  margin: 30px auto;
}

.action-card {
  cursor: pointer;
  transition: 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #34495e !important;
  color: white !important;
  text-align: center;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  word-break: keep-all !important;
}

.action-card:hover {
  background-color: #1abc9c !important;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2) !important;
  transform: scale(1.05);
}

.action-card h6 {
  font-size: 1.2rem;
}

/* Details card styling */
.details-card {
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Button styles */
/* button {
  padding: 10px 20px !important;
  font-size: 1rem !important;
  border-radius: 5px !important;
  transition: background-color 0.3s ease, transform 0.2s ease !important;
}

button:hover {
  background-color: #16a085 !important;
  transform: translateY(-3px) !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
} */

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
    height: auto;
    width: 100vw;
  }

  .welcome-card h4{
    font-size: 1.5rem;
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 999;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .sidebar.open {
    display: block; /* Show sidebar when open */
    transform: translateX(0); /* Slide in */  
  }

  .hamburger-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar h6 {
    font-size: 1rem !important;
  }

  .sidebar :global(.MuiTypography-root) {
    font-size: 0.8rem !important;
  }

  .dashboard-body {
    padding: 10px;
    min-width: 95%;
    margin-top: 100px;
  }

  .facultyMentorPage-header {
    left: 0 !important;
    width: 100% !important;
    top: 0 !important;
    border-radius: 0;
  }

  .dashboard-content {
    margin-top: 0;
    margin-left: 0;
    padding: 10px;
  }
}
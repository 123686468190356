.container {
  background-image: url('/public/bgimg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5%;
}

.form-content {
  max-width: 400px; /* Restrict the form width */
  width: 100%;
}

/* Media query for screens smaller than 576px */
@media (max-width: 576px) {
  .container {
    justify-content: center; /* Center the form */
    padding-left: 0; /* Remove left padding */
  }

  .form-content {
    max-width: 90%; /* Adjust form width for small screens */
  }
}

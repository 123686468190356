@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

/* Home Container */
.home-container {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.fixed-container {
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: url('/public/homeImg/cover.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}

/* Intro Section */
.intro {
  display: flex;
  position: absolute;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh; 
  width: 100vw; 
  z-index: 1; 
}

.intro-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 380px;
  opacity: 0.9;
  padding: 40px;
  z-index: 1;
  margin-left: 250px;
}

.intro-header img {
  width: 140px;
}

.intro-header h1 {
  line-height: 70px;
  font-family: 'Poppins', sans-serif;
  font-size: 3.8rem;
  font-weight: bold;
  margin-bottom: 1px;
  margin-top: 30px;
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.intro-header p {
  margin-top: 10px;
  font-family: "Dancing Script", cursive;
  font-weight: bolder;
  color: white;
  font-size: 2rem;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.button {
  width: 120px;
  padding: 12px 0;
  border-radius: 10px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 300;
  font-family:serif;
  border: 1px solid rgba(0, 0, 0, 0.2);
  
  /* Increased transparency by lowering the alpha value in rgba */
  background-color: #00ffcc46;
  color: #000;
  box-shadow: 0 4px 8px rgba(47, 39, 39, 0.2);
  height: 42px;
  margin: 0 auto;
  
  /* Gradient for the hover effect */
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3) 50%, #a1fff18a 50%);
  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 0.4s ease-in-out, color 0.4s ease-in-out;
}

.button:hover {
  transform:none;
  background-position: 0 0; /* Trigger the left-to-right transition */
  color: white; /* Optional: Change text color on hover */
}

.login-button {
  margin-right: 20px;
  color: black;
}

.signup-button {
  color: black;
}

.dean-container {
  padding: 40px 20px 20px 110px;
  height: 700px;
  background-color: #FBECFC;
  z-index: 1;
}

.dean-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dean-container h4 {
  margin-top: 30px;
  left: 20px;
  color: #a05656;
  font-weight: 750;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.dean-image img {
  width: 400px;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.dean-message {
  margin-left: 40px;
  padding: 10px;
  max-width: 800px;
  margin-bottom: 80px;
}

.dean-message h1 {
  max-width: 400px;
  font-size: 45px;
  font-weight: 700;
  color: #333;
  margin-bottom: 40px;
}

.dean-message p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  padding: 15px;
  border-radius: 8px;
}

.dean-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 0;
}

.dean-details h4 {
  font-size: 22px;
  font-weight: 700;
  color: #333;
}

.dean-details p {
  color: #a05656;
  font-weight: 750;
  font-size: 14.5px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

/* Carousel Section */

.carousel {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #0B1F38;
  text-align: center;
  z-index: 1;
  justify-content: center;
}

.club-carousel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  padding: 20px; /* Padding around the grid */
  margin: 0 auto; /* Centers the grid itself */
  max-width: 100%;
  padding-bottom: 80px;
}

.carousel h1 {
  margin: 30px auto;
  font-size: 2.5rem;
  font-family: Poppins, sans-serif;
  color: white;
  padding: 10px 20px;
}

.carousel h1 span {
  color: #019CB8;
}


/* Selector Section */
.selector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.selector-button {
  width: 120px;
  height: 45px;
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #1d4ed8;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.selector-button.active {
  background-color: #16a34a; 
}

.selector-button:hover {
  transform: scale(1.05);
}

.selector-button:not(.active):hover {
  background-color: #1e40af; 
}

/* Events Section */
.events-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #FBECFC;
  z-index: 2;
}

.events-section h1 {
  font-size: 2.5rem;
  color: #019CB8;
  margin-bottom: 50px;
  padding: 10px 20px;
}

.event-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  max-width: 900px;
  width: 100%;
  justify-content: center !important;
  margin: 0 auto;
}

.show-more-button {
  width: 200px !important;
  height: 50px !important;
  margin-top: 30px;
  padding: 12px 24px;
  background-color: #1d4ed8; /* Deep blue */
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1rem;
}

.show-more-button:hover {
  background-color: #1e40af; /* Slightly darker blue */
  transform: scale(1.05);
}


/* Modal Styles */
.club-modal,
.event-modal {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.club-modal.show,
.event-modal.show {
  opacity: 1;
}

/* Initial state for all sections */
.intro,
.carousel,
.events-section,
.dean-container,
.video-container {
  opacity: 0;
  transform: translateY(40px); /* Increased translation for more visible movement */
  transition: opacity 0.9s ease-out, transform 1s cubic-bezier(0.5, 1, 0.3, 1); /* Extended duration and changed easing for a stronger effect */
}

/* Visible state when elements are in view */
.intro.visible,
.carousel.visible,
.events-section.visible,
.dean-container.visible,
.video-container.visible {
  opacity: 1;
  transform: translateY(0); /* Element returns to its original position */
}

/* Add slight delay to opacity to create a staggered effect */
.intro,
.carousel,
.events-section,
.dean-container,
.video-container {
  transition-delay: 0.3s; /* Adds delay to opacity */
}

/* Scroll to top button */
.scroll-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.scroll-to-top:hover {
  opacity: 1;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust height as needed to fit the spinner in its section */
}

.cursive-text {
  font-family: 'Dancing Script', cursive; /* Apply the Google Font */
  font-size: 2rem;
  margin-bottom: 30px;
  line-height: 1.6;
  overflow: auto;
  color: #374151; /* Dark gray text for better readability */
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

/* Smaller than 768px */
@media (max-width: 768px) {
  .intro {
    padding: 15px;
    text-align: center;
    align-items: center;
  }

  .intro-header {
    margin-left: 0;
    padding: 20px;
    max-width: 90%;
  }

  .intro-header h1 {
    font-size: 2.5rem;
    line-height: 50px;
  }

  .intro-header p {
    font-size: 1.5rem;
  }

  .button {
    width: 100px;
    height: 38px;
    padding: 10px;
  }

  .dean-container {
    padding: 20px;
    height: auto;
  }

  .dean-body {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
  }

  .dean-image img {
    width: 250px;
  }

  .dean-message {
    margin-left: 0;
    max-width: 90%;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .dean-message h1 {
    font-size: 30px;
    margin-bottom: 0;
  }

  .dean-message p {
    font-size: 14px;
  }

  .carousel h1 {
    font-size: 2rem;
  }

  .club-carousel {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .event-cards {
    grid-template-columns: 1fr 1fr !important;
    gap: 15px;
  }
}

/* Smaller than 480px */
@media (max-width: 480px) {

  .intro-header {
    align-items: center;
  }

  .intro-header h1 {
    font-size: 2rem;
    line-height: 40px;
  }

  .button {
    width: 90px;
    height: 35px;
    font-size: 0.9rem;
  }

  .dean-container h4 {
    font-size: 1rem;
  }

  .dean-image img {
    width: 200px;
  }

  .dean-message h1 {
    font-size: 24px;
  }

  .dean-message p {
    font-size: 13px;
    padding: 10px;
  }

  .dean-details h4 {
    font-size: 18px;
  }

  .dean-details p {
    font-size: 13px;
  }

  .events-section h1 {
    font-size: 2rem;
    padding: 5px 10px;
  }

  .event-cards {
    grid-template-columns: 1fr !important;
    justify-items: center;
    gap: 15px;
    margin: 0 auto;
  }

  .event-cards > * {
    width: 250px; /* Set a fixed width for each card */
    max-width: 100%; /* Ensure it doesn't exceed the container width */
  }
}

/* Between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .intro-header {
    margin-left: 100px;
    padding: 30px;
  }

  .intro-header h1 {
    font-size: 3rem;
    line-height: 60px;
  }

  .dean-container {
    padding: 30px 15px 15px 90px; /* Slightly reduce padding */
  }
  
  .dean-message {
    max-width: 600px; /* Reduce width to fit comfortably */
    padding: 10px; /* Adjust padding to avoid overflow */
  }

  .dean-message h1 {
    font-size: 36px; /* Reduce font size slightly */
  }
  
  .dean-message p {
    font-size: 15px; /* Adjust font size for readability */
  }
  
  .dean-image img {
    width: 250px; /* Scale down image */
  }
  
  .club-carousel {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .event-cards {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .carousel h1 {
    font-size: 2.2rem;
  }
}

@media (max-height:400px) {
  .intro-header {
    scale:0.7;
    height: calc(100vh - 60px);
    margin-top: 30px;
  }
}